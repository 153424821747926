// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---cache-caches-gatsby-plugin-offline-app-shell-js": () => import("./../../.cache/caches/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---cache-caches-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-404-js": () => import("./../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-blog-js": () => import("./../../src/pages/blog.js" /* webpackChunkName: "component---src-pages-blog-js" */),
  "component---src-pages-contact-js": () => import("./../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-data-js": () => import("./../../src/pages/data.js" /* webpackChunkName: "component---src-pages-data-js" */),
  "component---src-pages-data-proj-1-js": () => import("./../../src/pages/data_proj1.js" /* webpackChunkName: "component---src-pages-data-proj-1-js" */),
  "component---src-pages-data-proj-2-js": () => import("./../../src/pages/data_proj2.js" /* webpackChunkName: "component---src-pages-data-proj-2-js" */),
  "component---src-pages-dl-js": () => import("./../../src/pages/dl.js" /* webpackChunkName: "component---src-pages-dl-js" */),
  "component---src-pages-dsa-js": () => import("./../../src/pages/dsa.js" /* webpackChunkName: "component---src-pages-dsa-js" */),
  "component---src-pages-graph-js": () => import("./../../src/pages/graph.js" /* webpackChunkName: "component---src-pages-graph-js" */),
  "component---src-pages-index-js": () => import("./../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-linkedlist-js": () => import("./../../src/pages/linkedlist.js" /* webpackChunkName: "component---src-pages-linkedlist-js" */),
  "component---src-pages-ml-js": () => import("./../../src/pages/ml.js" /* webpackChunkName: "component---src-pages-ml-js" */),
  "component---src-pages-ml-model-js": () => import("./../../src/pages/ml_model.js" /* webpackChunkName: "component---src-pages-ml-model-js" */),
  "component---src-pages-ml-proj-1-js": () => import("./../../src/pages/ml_proj1.js" /* webpackChunkName: "component---src-pages-ml-proj-1-js" */),
  "component---src-pages-ml-proj-2-js": () => import("./../../src/pages/ml_proj2.js" /* webpackChunkName: "component---src-pages-ml-proj-2-js" */),
  "component---src-pages-ml-proj-3-js": () => import("./../../src/pages/ml_proj3.js" /* webpackChunkName: "component---src-pages-ml-proj-3-js" */),
  "component---src-pages-ml-proj-4-js": () => import("./../../src/pages/ml_proj4.js" /* webpackChunkName: "component---src-pages-ml-proj-4-js" */),
  "component---src-pages-ml-project-js": () => import("./../../src/pages/ml_project.js" /* webpackChunkName: "component---src-pages-ml-project-js" */),
  "component---src-pages-nlp-js": () => import("./../../src/pages/nlp.js" /* webpackChunkName: "component---src-pages-nlp-js" */),
  "component---src-pages-nlp-model-1-js": () => import("./../../src/pages/nlp_model1.js" /* webpackChunkName: "component---src-pages-nlp-model-1-js" */),
  "component---src-pages-nlp-model-2-js": () => import("./../../src/pages/nlp_model2.js" /* webpackChunkName: "component---src-pages-nlp-model-2-js" */),
  "component---src-pages-nlp-model-js": () => import("./../../src/pages/nlp_model.js" /* webpackChunkName: "component---src-pages-nlp-model-js" */),
  "component---src-pages-nlp-proj-1-js": () => import("./../../src/pages/nlp_proj1.js" /* webpackChunkName: "component---src-pages-nlp-proj-1-js" */),
  "component---src-pages-nlp-proj-2-js": () => import("./../../src/pages/nlp_proj2.js" /* webpackChunkName: "component---src-pages-nlp-proj-2-js" */),
  "component---src-pages-nlp-project-js": () => import("./../../src/pages/nlp_project.js" /* webpackChunkName: "component---src-pages-nlp-project-js" */),
  "component---src-pages-projects-js": () => import("./../../src/pages/projects.js" /* webpackChunkName: "component---src-pages-projects-js" */),
  "component---src-pages-py-proj-1-js": () => import("./../../src/pages/py_proj1.js" /* webpackChunkName: "component---src-pages-py-proj-1-js" */),
  "component---src-pages-py-proj-10-js": () => import("./../../src/pages/py_proj10.js" /* webpackChunkName: "component---src-pages-py-proj-10-js" */),
  "component---src-pages-py-proj-11-js": () => import("./../../src/pages/py_proj11.js" /* webpackChunkName: "component---src-pages-py-proj-11-js" */),
  "component---src-pages-py-proj-12-js": () => import("./../../src/pages/py_proj12.js" /* webpackChunkName: "component---src-pages-py-proj-12-js" */),
  "component---src-pages-py-proj-13-js": () => import("./../../src/pages/py_proj13.js" /* webpackChunkName: "component---src-pages-py-proj-13-js" */),
  "component---src-pages-py-proj-14-js": () => import("./../../src/pages/py_proj14.js" /* webpackChunkName: "component---src-pages-py-proj-14-js" */),
  "component---src-pages-py-proj-15-js": () => import("./../../src/pages/py_proj15.js" /* webpackChunkName: "component---src-pages-py-proj-15-js" */),
  "component---src-pages-py-proj-16-js": () => import("./../../src/pages/py_proj16.js" /* webpackChunkName: "component---src-pages-py-proj-16-js" */),
  "component---src-pages-py-proj-17-js": () => import("./../../src/pages/py_proj17.js" /* webpackChunkName: "component---src-pages-py-proj-17-js" */),
  "component---src-pages-py-proj-18-js": () => import("./../../src/pages/py_proj18.js" /* webpackChunkName: "component---src-pages-py-proj-18-js" */),
  "component---src-pages-py-proj-19-js": () => import("./../../src/pages/py_proj19.js" /* webpackChunkName: "component---src-pages-py-proj-19-js" */),
  "component---src-pages-py-proj-2-js": () => import("./../../src/pages/py_proj2.js" /* webpackChunkName: "component---src-pages-py-proj-2-js" */),
  "component---src-pages-py-proj-20-js": () => import("./../../src/pages/py_proj20.js" /* webpackChunkName: "component---src-pages-py-proj-20-js" */),
  "component---src-pages-py-proj-21-js": () => import("./../../src/pages/py_proj21.js" /* webpackChunkName: "component---src-pages-py-proj-21-js" */),
  "component---src-pages-py-proj-22-js": () => import("./../../src/pages/py_proj22.js" /* webpackChunkName: "component---src-pages-py-proj-22-js" */),
  "component---src-pages-py-proj-23-js": () => import("./../../src/pages/py_proj23.js" /* webpackChunkName: "component---src-pages-py-proj-23-js" */),
  "component---src-pages-py-proj-24-js": () => import("./../../src/pages/py_proj24.js" /* webpackChunkName: "component---src-pages-py-proj-24-js" */),
  "component---src-pages-py-proj-25-js": () => import("./../../src/pages/py_proj25.js" /* webpackChunkName: "component---src-pages-py-proj-25-js" */),
  "component---src-pages-py-proj-26-js": () => import("./../../src/pages/py_proj26.js" /* webpackChunkName: "component---src-pages-py-proj-26-js" */),
  "component---src-pages-py-proj-27-js": () => import("./../../src/pages/py_proj27.js" /* webpackChunkName: "component---src-pages-py-proj-27-js" */),
  "component---src-pages-py-proj-28-js": () => import("./../../src/pages/py_proj28.js" /* webpackChunkName: "component---src-pages-py-proj-28-js" */),
  "component---src-pages-py-proj-29-js": () => import("./../../src/pages/py_proj29.js" /* webpackChunkName: "component---src-pages-py-proj-29-js" */),
  "component---src-pages-py-proj-3-js": () => import("./../../src/pages/py_proj3.js" /* webpackChunkName: "component---src-pages-py-proj-3-js" */),
  "component---src-pages-py-proj-30-js": () => import("./../../src/pages/py_proj30.js" /* webpackChunkName: "component---src-pages-py-proj-30-js" */),
  "component---src-pages-py-proj-31-js": () => import("./../../src/pages/py_proj31.js" /* webpackChunkName: "component---src-pages-py-proj-31-js" */),
  "component---src-pages-py-proj-32-js": () => import("./../../src/pages/py_proj32.js" /* webpackChunkName: "component---src-pages-py-proj-32-js" */),
  "component---src-pages-py-proj-33-js": () => import("./../../src/pages/py_proj33.js" /* webpackChunkName: "component---src-pages-py-proj-33-js" */),
  "component---src-pages-py-proj-34-js": () => import("./../../src/pages/py_proj34.js" /* webpackChunkName: "component---src-pages-py-proj-34-js" */),
  "component---src-pages-py-proj-35-js": () => import("./../../src/pages/py_proj35.js" /* webpackChunkName: "component---src-pages-py-proj-35-js" */),
  "component---src-pages-py-proj-4-js": () => import("./../../src/pages/py_proj4.js" /* webpackChunkName: "component---src-pages-py-proj-4-js" */),
  "component---src-pages-py-proj-5-js": () => import("./../../src/pages/py_proj5.js" /* webpackChunkName: "component---src-pages-py-proj-5-js" */),
  "component---src-pages-py-proj-6-js": () => import("./../../src/pages/py_proj6.js" /* webpackChunkName: "component---src-pages-py-proj-6-js" */),
  "component---src-pages-py-proj-7-js": () => import("./../../src/pages/py_proj7.js" /* webpackChunkName: "component---src-pages-py-proj-7-js" */),
  "component---src-pages-py-proj-8-js": () => import("./../../src/pages/py_proj8.js" /* webpackChunkName: "component---src-pages-py-proj-8-js" */),
  "component---src-pages-py-proj-9-js": () => import("./../../src/pages/py_proj9.js" /* webpackChunkName: "component---src-pages-py-proj-9-js" */),
  "component---src-pages-pythonprojectcard-js": () => import("./../../src/pages/pythonprojectcard.js" /* webpackChunkName: "component---src-pages-pythonprojectcard-js" */),
  "component---src-pages-queue-js": () => import("./../../src/pages/queue.js" /* webpackChunkName: "component---src-pages-queue-js" */),
  "component---src-pages-resume-js": () => import("./../../src/pages/resume.js" /* webpackChunkName: "component---src-pages-resume-js" */),
  "component---src-pages-searching-js": () => import("./../../src/pages/searching.js" /* webpackChunkName: "component---src-pages-searching-js" */),
  "component---src-pages-sorting-js": () => import("./../../src/pages/sorting.js" /* webpackChunkName: "component---src-pages-sorting-js" */),
  "component---src-pages-special-proj-js": () => import("./../../src/pages/special_proj.js" /* webpackChunkName: "component---src-pages-special-proj-js" */),
  "component---src-pages-stack-js": () => import("./../../src/pages/stack.js" /* webpackChunkName: "component---src-pages-stack-js" */),
  "component---src-pages-tech-blogpage-js": () => import("./../../src/pages/tech_blogpage.js" /* webpackChunkName: "component---src-pages-tech-blogpage-js" */),
  "component---src-pages-tree-js": () => import("./../../src/pages/tree.js" /* webpackChunkName: "component---src-pages-tree-js" */),
  "component---src-pages-web-proj-1-js": () => import("./../../src/pages/web_proj1.js" /* webpackChunkName: "component---src-pages-web-proj-1-js" */),
  "component---src-pages-web-proj-2-js": () => import("./../../src/pages/web_proj2.js" /* webpackChunkName: "component---src-pages-web-proj-2-js" */),
  "component---src-pages-web-proj-3-js": () => import("./../../src/pages/web_proj3.js" /* webpackChunkName: "component---src-pages-web-proj-3-js" */),
  "component---src-pages-web-proj-4-js": () => import("./../../src/pages/web_proj4.js" /* webpackChunkName: "component---src-pages-web-proj-4-js" */),
  "component---src-pages-web-proj-5-js": () => import("./../../src/pages/web_proj5.js" /* webpackChunkName: "component---src-pages-web-proj-5-js" */),
  "component---src-pages-web-proj-6-js": () => import("./../../src/pages/web_proj6.js" /* webpackChunkName: "component---src-pages-web-proj-6-js" */),
  "component---src-pages-webprojectcard-js": () => import("./../../src/pages/webprojectcard.js" /* webpackChunkName: "component---src-pages-webprojectcard-js" */)
}

